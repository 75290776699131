@import "rsuite/dist/rsuite.css";
@import 'components/dataTables/dataTables.dataTables.min.css';

@import '@fontsource/roboto/300.css';
@import '@fontsource/roboto/400.css';
@import '@fontsource/roboto/500.css';
@import '@fontsource/roboto/700.css';

:root {
  --yl-primary: #358355;
  --yl-secondary: #358355;
}

/* make the link or button hover color the yl-primary color */
a:hover, button:hover, .btn:hover, .btn:hover * {
  color: var(--yl-primary);
}

.App {
  text-align: center;
  height: 100vh;
  overflow: hidden;
  font-family: Roboto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

::-webkit-scrollbar {
  width: 0.5vw;
  height: 0.5vw;
  /*   height: 50%; */
  border-radius: 0.2rem;
}

::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 1vw var(--yl-primary);
  border-radius: 1rem;
}

[class$="MUIDataTable-responsiveScroll"] {
  position: relative !important;
  max-height: 70vh !important;
  width: 100% !important; /* Add this line */
  height: 100% !important; /* Add this line */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (max-width: 600px) {
  .FloatingSettingsButton {
    position: relative;
    margin: 5px;
  }

  [class$="MUIDataTable-responsiveScroll"] {
    position: relative !important;
    max-height: 65vh !important;
    width: 100% !important; /* Add this line */
    height: 100% !important; /* Add this line */
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html, body {
  overflow: hidden;
}

@media print {

  html, body {
    height: auto;
    overflow: visible;
    margin-top: 1cm;
  }

  body * {
    visibility: hidden;
  }

  body .print-area, .print-area * {
    visibility: visible;
  }

  body .print, .print * {
    visibility: visible;
    display: block;
  }

  .noPrint, .noPrint * {
    display: none;
  }

  .main {
    display: block !important;
    overflow: visible !important;
    margin: 5px;
    padding: 5px;
    visibility: visible;
  }

  .print {
    display: block !important;
    overflow: visible;
    width: 100%;
  }

  #dashBox {
    display: block;
    width: 100%;
    box-shadow: none; 
    overflow-y: visible;
  }

  .chartBox {
    display: block;
    width: 100%;
    margin-bottom: 20px;
  }

  .pData {
    display: block;
    position: relative;
    /* height: 100%; */
    width: 100%; /* This prevents breaking inside a chart */
    page-break-after: always;
    margin: 0px;
    margin-top: 20px;
    margin-bottom: 100px;
  }

  #dataCharts {
    overflow-y: visible;
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    /* page-break-inside: avoid; */
    page-break-after: always;
    page-break-before: always;
    max-height: none;
    padding: 5px;
    margin-top: 20px;
  }

  #dataCharts {
    display: grid;
    overflow-y: visible;
    width: 100%; 
    max-height: none;
    page-break-before: always;
    /* page-break-inside: avoid; */
  }

  .r-chart {
    display: block;
    overflow: visible;
    width: 100%; 
    max-height: none;
    page-break-inside: avoid;
    page-break-after: auto;
    position: relative;
    margin-top: 100px;
    margin-bottom: 50px;
  }

  .page-break {
    page-break-before: always;
    display: block; 
    height: 1px;
  }
}
